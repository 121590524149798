

















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IMessage, IMessagesSearchParams } from '@/interfaces';
import { dispatchGetMessages } from '@/store/admin/accessors';
import { readHasAdminAccess } from '@/store/main/accessors';

@Component
export default class Messages extends Vue {
  public messageId: string | null = null;
  public search: string = '';
  public loading: boolean = false;
  public messages: IMessage[] = [];
  public totalMessages: number = 0;

  public range = [];
  public sender: string = '';
  public receiver: string = '';

  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: ['id'],
    descending: [false],
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public async updateMessages(clearPagination?: boolean) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.totalMessages = data.total;
    this.messages = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateMessages();
  }

  public get headers() {
    const headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.service'),
        value: 'service',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sender'),
        value: 'sender',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.receiver'),
        value: 'receiver',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.status'),
        value: 'status',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.error'),
        value: 'error',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.created'),
        value: 'created',
        align: 'left',
      },
      {
        text: '',
        value: 'data-table-expand',
      },
    ];
    if (this.hasAdminAccess) {
      headers.splice(1, 0, {
        text: this.$vuetify.lang.t('$vuetify.user'),
        value: 'user',
        align: 'left',
      });
    }
    return headers;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async mounted() {
    const messageId = this.$router.currentRoute.query.id;
    if (messageId) {
      this.messageId = String(messageId);
    }
    await this.updateMessages();
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: IMessagesSearchParams = {
      skip,
      limit: rowsPerPage,
      orderBy: sortBy[0],
      descending: descending[0],
      fromDate: null,
      toDate: null,
      receiver: null,
      sender: null,
      messageId: this.messageId,
    };
    if (this.range.length === 2) {
      params.fromDate = this.range[0];
      params.toDate = this.range[1];
    }
    if (this.sender) {
      params.sender = this.sender;
    }
    if (this.receiver) {
      params.receiver = this.receiver;
    }
    return await dispatchGetMessages(this.$store, params);
  }
}
